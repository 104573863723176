import React, { Fragment, useState, useEffect } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Flex, Box } from '@rebass/grid';
import { Field, Form } from 'react-final-form';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faApple } from '@fortawesome/free-brands-svg-icons';

import ReactPixel from 'react-facebook-pixel';
import URLS from 'routes/routeUrls';
import useNativeBridge from 'hooks/useNativeBridge';

import {
  ButtonFacebook,
  ButtonApple,
  ButtonGoogleWrapper,
} from 'styledComponents/elements/Button';

import RegisterForm from 'views/Auth/components/Form/RegisterForm';
import LoginForm from 'views/Auth/components/Form/LoginForm';

import FormRadio from 'common/components/Form/FormRadio';
import FormRadioGroup from 'common/components/Form/FormRadioGroup';

import {
  REGISTER_FORM,
  LOGIN_FORM,
  useAuthTabs,
} from 'views/Auth/const/authTabs';

import { login } from '../actions/authActions';
import GoogleButton from 'react-google-button';
import { OnChange } from 'react-final-form-listeners';
import { useLocalUrl } from 'routes';
import { withTranslation } from 'react-i18next';
import { BASE_URL } from 'configuration';
import { gapi } from 'gapi-script';

const getAuthView = (displayedForm, props) => {
  switch (displayedForm) {
    case LOGIN_FORM:
      return <LoginForm {...props} />;
    case REGISTER_FORM:
      return <RegisterForm {...props} />;
    default:
      return null;
  }
};

const Auth = ({
  t,
  match,
  token,
  history,
  brandId,
  selectedForm,
  authServices,
  disableTracking,
  ...props
}) => {
  const { postMessageToNative, addNativeEventListener } = useNativeBridge();
  const authTabs = useAuthTabs();
  const { toLocalUrl } = useLocalUrl();
  const isLogin = history.location.pathname === toLocalUrl(URLS.AUTH_LOGIN);
  const initialForm = selectedForm
    ? selectedForm
    : isLogin
    ? LOGIN_FORM
    : REGISTER_FORM;
  const [currentForm, setCurrentForm] = useState(initialForm);
  const isRegister =
    history.location.pathname === toLocalUrl(URLS.AUTH_REGISTER);

  useEffect(() => {
    // If token exists redirect to USER DIETS
    if (token) {
      history.push(URLS.USER_DIET);
    }

    addNativeEventListener(event => {
      var data = event.data;
      if (data && data.type) {
        console.log('nativeEventListener', data);
        switch (data.type) {
          case 'GOOGLE_SIGN_IN':
            const googleResult = data.result;
            googleResult.tokenId = googleResult.idToken;
            console.log('onGoogleLogin', googleResult, currentForm);
            onGoogleLogin(googleResult, currentForm);
            break;
          case 'FB_SIGN_IN':
            const fbResult = data.result;
            console.log('onFacebookLogin', fbResult, currentForm);
            onFacebookLogin(fbResult, currentForm);
            break;
        }
      }
    });
  }, []);

  useEffect(() => {
    if (authServices.google.enabled) {
      const start = () => {
        gapi.client.init({
          clientId: authServices.google.id,
          scope: 'email',
        });
      };

      gapi.load('client:auth2', start);
    }
  }, [authServices.google.enabled, authServices.google.id]);

  const commonLogin = ({ loginProps, displayedForm, loginProvider }) => {
    localStorage.setItem('keepMeLoggedIn', 'true');
    const { login } = props;

    login(loginProps, t)
      .then(() => {
        if (!disableTracking) {
          const trackName =
            displayedForm === REGISTER_FORM
              ? isRegister
                ? t(`$*oAuth.registerVia${loginProvider}`)
                : t(`$*oAuth.clientData${loginProvider}Registration`)
              : isLogin
              ? t(`$*oAuth.loginVia${loginProvider}`)
              : t(`$*oAuth.clientData${loginProvider}Login`);

          ReactPixel.trackCustom(trackName);

          if (displayedForm === REGISTER_FORM) {
            ReactPixel.track('CompleteRegistration');
          }
        }

        if (props.onAfterSign) {
          return props.onAfterSign();
        } else {
          history.push(URLS.USER_DIET);
        }
      })
      .catch(() => history.push(URLS.USER_DIET));
  };

  const onFacebookLogin = ({ accessToken, id }, displayedForm) => {
    commonLogin({
      loginProps: { accessToken, id },
      displayedForm,
      loginProvider: 'Facebook',
    });
  };

  const onFbLogin = (response, displayedForm) => {
    commonLogin({
      loginProps: {
        tokenId: response.tokenId,
        id: authServices.google.id,
      },
      displayedForm,
      loginProvider: 'Google',
    });
  };

  const onGoogleLogin = (response, displayedForm) => {
    commonLogin({
      loginProps: {
        tokenId: response.tokenId,
        id: authServices.google.id,
      },
      displayedForm,
      loginProvider: 'Google',
    });
  };

  const handleCliclAppleAuth = () => {
    const appleClientId = authServices.apple.clientId;
    const redirectUri = `${BASE_URL}/frontend/auth/apple/${brandId}`;
    // /auth/apple?token=token&refreshToken=refreshToken [TODO: newPage]
    // /auth/apple/error [TODO: newPage]
    const url = `https://appleid.apple.com/auth/authorize?response_type=code&client_id=${appleClientId}&scope=email%20name&response_mode=form_post&redirect_uri=${redirectUri}`;

    // history.push(url);
    window.location.href = url;
    // window.open(url, '_blank').focus();
  };

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{
        displayedForm: currentForm,
      }}
      render={({ values: { displayedForm } }) => (
        <Fragment>
          <FormRadioGroup>
            {authTabs.map(tab => {
              return (
                <Fragment key={tab.id}>
                  <Field
                    name="displayedForm"
                    type="radio"
                    value={tab.id.toString()}
                    component={FormRadio}
                    componentStyle="secondary"
                    label={tab.label}
                    isCentered
                    hasAutoHeight
                  />
                  {!selectedForm && (
                    <OnChange name="displayedForm">
                      {() => {
                        setCurrentForm(tab.id.toString());
                        history.push(displayedForm);
                      }}
                    </OnChange>
                  )}
                </Fragment>
              );
            })}
          </FormRadioGroup>

          <Box pt={16}>
            {authServices.apple.enabled && (
              <Flex my={16} justifyContent="center">
                <Box>
                  <ButtonApple
                    type="button"
                    sizeMiddle
                    onClick={handleCliclAppleAuth}
                  >
                    <FontAwesomeIcon icon={faApple} />
                    <span>
                      {displayedForm === REGISTER_FORM
                        ? t('$*oAuth.registerViaApple')
                        : t('$*oAuth.loginViaApple')}
                    </span>
                  </ButtonApple>
                </Box>
              </Flex>
            )}

            {authServices.facebook.enabled &&
              !props.disableThirdPartyLoginMethods && (
                <Flex my={16} justifyContent="center">
                  <Box>
                    <FacebookLogin
                      appId={authServices.facebook.id}
                      autoLoad={false}
                      disableMobileRedirect={true}
                      fields="name,email"
                      version="4.0"
                      callback={response =>
                        onFacebookLogin(response, displayedForm)
                      }
                      render={({ onClick }) => (
                        <ButtonFacebook
                          type="button"
                          sizeMiddle
                          onClick={
                            props.useNativeBridge
                              ? () =>
                                  postMessageToNative({
                                    type: 'FB_SIGN_IN',
                                    id: authServices.facebook.id,
                                    fields: 'name,email',
                                  })
                              : onClick
                          }
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                          <span>
                            {displayedForm === REGISTER_FORM
                              ? t('$*oAuth.registerViaFacebook')
                              : t('$*oAuth.loginViaFacebook')}
                          </span>
                        </ButtonFacebook>
                      )}
                    />
                  </Box>
                </Flex>
              )}
            {authServices.google.enabled &&
              !props.disableThirdPartyLoginMethods && (
                <Flex my={16} justifyContent="center">
                  <Box>
                    <ButtonGoogleWrapper>
                      <GoogleLogin
                        onSuccess={response =>
                          onGoogleLogin(response, displayedForm)
                        }
                        render={renderProps => {
                          return (
                            <GoogleButton
                              style={{
                                width: '290px',
                                textAlign: 'left',
                              }}
                              type="light"
                              label={
                                displayedForm === REGISTER_FORM
                                  ? t('$*oAuth.registerViaGoogle')
                                  : t('$*oAuth.loginViaGoogle')
                              }
                              onClick={
                                props.useNativeBridge
                                  ? () =>
                                      postMessageToNative({
                                        type: 'GOOGLE_SIGN_IN',
                                      })
                                  : renderProps.onClick
                              }
                            />
                          );
                        }}
                        clientId={authServices.google.id}
                      />
                    </ButtonGoogleWrapper>
                  </Box>
                </Flex>
              )}
          </Box>
          <Flex justifyContent="center">
            <Box>{getAuthView(displayedForm, { ...props })}</Box>
          </Flex>
        </Fragment>
      )}
    />
  );
};

const mapStateToProps = ({
  app: {
    brand: { id },
    config,
  },
  nativeAppConfig: {
    disableThirdPartyLoginMethods,
    disableTracking,
    useNativeBridge,
  },
  auth: { token },
}) => ({
  brandId: id,
  authServices: config?.modules?.AuthServices ?? {},
  token,
  disableThirdPartyLoginMethods,
  useNativeBridge,
  disableTracking,
});

const mapDispatchToProps = { login };

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Auth);
